<script>
  import BillStepItem from '@/views/User/Order/Detail/component/BillStepItem.vue'

  export default {
    name: 'BillStep',
    props: {
      payDetail: {
        type: Array,
        required: true
      },
      hasRefundDetail: {
        type: Boolean,
        required: true

      },
      orderStatus: {
        type: Number,
        required: true
      }
    },
    components: {
      BillStepItem
    }

  }

</script>

<template>
  <div class="bill-step">
    <BillStepItem v-for="(step,index) in payDetail" :stepData="step" :stepIndex="index + 1" :orderStatus="orderStatus" :hasRefundDetail="hasRefundDetail" :key="index"></BillStepItem>
  </div>
</template>

<style scoped lang="less">
  .bill-step {
    width: 100%;
    padding: 20px;
    background: #fff;
    box-sizing: border-box;
    //.bill-step-item-box:before{
    //  content: '';
    //  position: absolute;
    //  left: 0;
    //  top: 6px;
    //  width: 8px;
    //  height: 8px;
    //  border-radius: 50%;
    //  border: 2px solid #0A0A0A;
    //
    //}
  }
</style>

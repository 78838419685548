<template>
  <div class="order-detail">
    <div class="top">
      <router-link to="/user/orders/list">
        <i class="iconfont icon-papa-rt"></i>
        订单列表
      </router-link>
    </div>
    <div class="order-type" v-if="orderDetail.status===PAY_STATUS.PAID">交易成功</div>
    <div class="order-type" v-else-if="orderDetail.status===PAY_STATUS.CLOSED">交易已关闭</div>
    <div class="order-type" v-else-if="orderDetail.status===PAY_STATUS.UNPAID">待支付</div>
    <div class="order-type" v-else-if="orderDetail.status===PAY_STATUS.PARTPAID">部分付款</div>
    <div class="order-type" v-else-if="orderDetail.status===PAY_STATUS.REFUNDING">退款中</div>
    <div class="order-type" v-else-if="orderDetail.status===PAY_STATUS.REFUND">已退款</div>
    <div class="order-type" v-else-if="orderDetail.status===PAY_STATUS.INVALID">已作废</div>
    <div class="order-type" v-else-if="orderDetail.status===PAY_STATUS.CASH">部分退款</div>
    <div class="course-list">
      <div class="course-item" v-for="item in orderDetail.details" :key="item.course.id">
        <div class="course-pic">
          <img :src=item.course.cover_image_url alt="">
          <div class="course-desc">
            <div class="course-name">{{item.course.name}}</div>
            <div class="course-subject">科目{{item.course_subject}}</div>
            <div class="course-teacher">教师{{item.teacher_level}}</div>
            <div class="course-price">价格{{item.unit_price}}元</div>

          </div>
        </div>
        <div class="course-qty">x{{item.purchase_qty}}</div>
      </div>
      <div class="total" style="text-align: right; border-bottom: 1px solid #ececec">总计：{{orderDetail.bill_amount}}</div>
    </div>
    <div class="bill-step-box" v-if="orderDetail.pay_details">
      <BillStep :payDetail="orderDetail.pay_details" :hasRefundDetail="orderDetail.hasRefundDetail" :orderStatus="orderDetail.status"></BillStep>
    </div>
    <div class="order-desc">
      <div class="desc-item"  v-show="orderDetail.delivery&&orderDetail.delivery.district">
        <div class="key">收件人</div>
        <div class="value" v-if="orderDetail.delivery">
          {{orderDetail.delivery.name}}
        </div>
      </div>
      <div class="desc-item"  v-show="orderDetail.delivery&&orderDetail.delivery.district">
        <div class="key">联系方式</div>
        <div class="value" v-if="orderDetail.delivery">
          {{orderDetail.delivery.mobile}}
        </div>
      </div>
      <div class="address desc-item" v-show="orderDetail.delivery&&orderDetail.delivery.district">

        <div class="key">收货地址</div>
        <div class="value" v-if="orderDetail.delivery">
          {{orderDetail.delivery.district}}{{orderDetail.delivery.address}}
        </div>
        <i class="iconfont icontip"></i>
      </div>
      <div class="orderSn desc-item">
        <div class="key">订单编号</div>
        <div class="value">{{orderDetail.order_sn}}</div>
      </div>
      <div class="createTime desc-item">
        <div class="key">创建时间</div>
        <div class="">{{formatMoment(orderDetail.created_at)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as Api from '@/api/user/user.js'
  import {formatMoment} from '@/utils/utils'
  import BillStep from '@/views/User/Order/Detail/component/BillStep.vue'
  import { PAY_STATUS } from '@/enums/API'
  export default {
    name: 'Detail',
    data () {
      return {
        orderDetail: {},
        PAY_STATUS
      }
    },
    components: {
      BillStep
    },
    mounted () {
      this.getOrder()
    },
    methods: {
      getOrder () {
        let orderSn = this.$route.query.order_sn
        Api.userOrder('get', '', (res) => {
          res.bill_amount =  (res.bill_amount / 100).toFixed(2)
          res.hasRefundDetail = false
          res.pay_details.forEach(item=>{
            item.receivable_amount =  (item.receivable_amount / 100).toFixed(2)
            item.paid_at = formatMoment(item.paid_at)
            if(item.order_pay_detail_records.length>0){
              res.hasRefundDetail = true
              item.order_pay_detail_records.forEach(
                record=>{
                  record.refunded_at = formatMoment(record.refunded_at)
                  record.refund_amount =  (record.refund_amount / 100).toFixed(2)
                }
              )
            }
          })
          res.details.forEach(item=>{
            item.paid_amount =  (item.paid_amount / 100).toFixed(2)
            item.unit_price =  (item.unit_price / 100).toFixed(2)
          })
          this.orderDetail = res
        }, '', 'v1', orderSn)
      },
      formatMoment
    }
  }
</script>

<style lang="less" scoped>
  .order-detail {
    & > .top {
      padding-bottom: 20px;
      border-bottom: 1px solid #e6e6e6;
      text-align: left;
      font-size: 16px;
      color: #73797F;
      font-weight: bold;
    }

    & > .order-type {
      font-size: 16px;
      color: #333;
      font-weight: bold;
      text-align: left;
      margin: 20px 0;
    }

    & > .course-list {
      & > .course-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        & > .course-pic {
          display: flex;

          & > img {
            width: 144px;
            height: 80px;
            margin-right: 15px;
          }

          & > .course-desc {
            text-align: left;
            color: #555;
            font-size: 14px;

            & > .course-name {
              font-size: 16px;
              font-weight: bold;
              color: #333;
              margin-bottom: 8px;
            }

          }
        }
      }
    }

    & > .order-desc {
      margin-bottom: 15px;

      & > .desc-item {
        display: flex;

        & > .key {
          text-align: left;
          width: 4em;
          margin-right: 10px;
        }
      }

      & > .address {
        position: relative;

        & > .icontip {
          position: absolute;
          right: 0;
        }
      }
    }

    & > .money {
      width: 100%;
      background: #F7F7F7;
      box-sizing: border-box;
      padding: 20px;

      & > .desc-item {
        display: flex;
        margin-bottom: 6px;

        & > .key {
          text-align: left;
          width: 4em;
          margin-right: 10px;
        }
      }
    }

  }

</style>

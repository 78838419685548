<script>
  import { PAY_DETAIL_STATUS_MAP, PAY_DETAIL_STATUS } from '@/enums/API'


  export default {
    name: 'BillStepItem',
    props: {
      stepData: {
        type: Object,
        required: true
      },
      stepIndex: {
        type: Number,
        required: true
      },
      orderStatus: {
        type: Number,
        required: true
      },
      hasRefundDetail: {
        type: Boolean,
        required: true
      }
    },
    data () {
      return {
        isShowDetail: false,
        PAY_DETAIL_STATUS, PAY_DETAIL_STATUS_MAP
      }
    },
    methods: {
      handleShowDetail () {
        this.isShowDetail = true
      }
    }
  }

</script>

<template>
  <div class="bill-step-item" v-if="stepData">
    <div class="item-left">
      <div class="left-circle" :class="`left-circle-${stepData.status}`"></div>
      <div class="left-line"></div>
    </div>
    <div class="item-right">
      <div class="top">
        <div class="left">
          <div class="step-circle"></div>
          <div class="step-index" v-if="stepIndex">第 <span>{{ stepIndex }}笔支付</span></div>
          <div class="step-status" :class="`step-status-${stepData.status}`">{{
              PAY_DETAIL_STATUS_MAP[ stepData.status ]
            }}
          </div>
        </div>
        <div class="price">￥{{ stepData.receivable_amount }}</div>
      </div>
      <div class="content">
        <div class="content-item order-no">商户交易单号：{{ stepData.out_trade_no }}</div>
        <div class="content-item pay-type">支付方式：{{ stepData.pay_method }}</div>
        <div class="content-item pay-time">支付时间：{{ stepData.paid_at }}</div>
        <div class="refund-content"
             v-if="orderStatus === PAY_DETAIL_STATUS.FULL_AMOUNT_REFUND || orderStatus === PAY_DETAIL_STATUS.PARTIAL_REFUND">
          <template v-if="stepData.order_pay_detail_records.length>0">
            <div class="expend-refund-detail no-detail" v-if="!isShowDetail" @click="handleShowDetail">
              <span>展开退款明细</span> <i class="iconfont icon-papa-rt"></i></div>
            <template v-if="isShowDetail">
              <div class="refund-content-item" v-for="item in stepData.order_pay_detail_records">
                <div class="refund-title">退款明细</div>
                <div class="refund-no">
                  退款流水单号：{{ item.refund_out_trade_no }}
                </div>
                <div class="refund-type">
                  退款类型：{{ item.refund_type }}
                </div>
                <div class="refund-method">
                  退款方式：{{ item.refund_method }}
                </div>
                <div class="refund-time">
                  退款时间：{{ item.refunded_at }}
                </div>
                <div class="refund-amount">
                  退款金额：{{ item.refund_amount }}
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div v-if="!hasRefundDetail" class="no-detail">退款明细生成中，预计七个工作日完成</div>
          </template>

        </div>
      </div>
    </div>

  </div>
</template>

<style scoped lang="less">
  .bill-step-item {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    text-align: left;

    .item-left {
      box-sizing: border-box;
      padding: 5px 5px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .left-circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 2px solid red;
        background: red;
      }

      .left-circle-100 {
        border-color: #f1a42a;
        background: #f1a42a;
      }

      .left-circle-101 {
        border-color: #3bb143;
        background: transparent;
      }

      .left-line {
        width: 0.5px;
        flex: 1;
        background: #ccc;
        margin-bottom: -5px;
      }
    }

    .item-right {
      flex: 1;
    }

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .left {
        display: flex;
        align-items: center;

        .step-index {
          font-size: 14px;
          font-weight: bold;
          margin-right: 4px;
        }

        .step-status {
          font-size: 12px;
          padding: 2px 3px;
          border-radius: 2px;
          color: red;
          background: rgba(255, 0, 0, 0.1);
        }

        .step-status-101 {
          color: #3bb143;
          background: #f0f9f0;
        }

        .step-status-100 {
          color: #f1a42a;
          background: #fef8ef;
        }
      }
    }

    .content {
      font-size: 13px;

      .content-item {
        margin-bottom: 4px;
      }

      .refund-content {
        .no-detail, .refund-content-item {
          margin: 10px 0;
          text-align: center;
          background: #f2f4f6;
          padding: 4px 10px;
          border-radius: 6px;
          color: #555;

          .iconfont {
            font-size: 12px;
            transform: rotate(270deg);
            position: absolute;
            top: 2px;
          }
        }

        .expend-refund-detail {
          cursor: pointer;
          position: relative;
        }

        .refund-content-item {
          text-align: left;

          .refund-title {
            font-weight: bold;
            font-size: 14px;
            color: #333;
            margin-bottom: 10px;
            margin-top: 5px;
          }
        }
      }
    }
  }
</style>
